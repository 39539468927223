import logo from "./logo.svg";
import "./App.css";
import Resizer from "react-image-file-resizer";
import { useState, useRef } from "react";

function App() {
  const [picture, setPicture] = useState("");

  const searchParams = new URLSearchParams(window.location.search);
  const hiddenFileInput = useRef(null);
  const mode = searchParams.get("mode");
  const familyKey = searchParams.get("fam");
  const key = searchParams.get("key");

  console.log(mode);
  console.log(key);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onSelect = async (event) => {
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      //console.log(image);
      setPicture(image);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const uploadProfile = () => {
    return (
      <div className="content">
        <h3>Hello,</h3>
        <p>Please upload your photo</p>
        {picture == "" && (
          <div className="uploader-wrapper" onClick={handleClick}>
            <div className="profile-img-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="82px"
                viewBox="0 -960 960 960"
                width="82px"
                fill="#9796B5"
              >
                <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-240v-32q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v32q0 33-23.5 56.5T720-160H240q-33 0-56.5-23.5T160-240Z" />
              </svg>
            </div>
            <div className="upload-img-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FFFFFF"
              >
                <path d="M240-160q-33 0-56.5-23.5T160-240v-80q0-17 11.5-28.5T200-360q17 0 28.5 11.5T240-320v80h480v-80q0-17 11.5-28.5T760-360q17 0 28.5 11.5T800-320v80q0 33-23.5 56.5T720-160H240Zm200-486-75 75q-12 12-28.5 11.5T308-572q-11-12-11.5-28t11.5-28l144-144q6-6 13-8.5t15-2.5q8 0 15 2.5t13 8.5l144 144q12 12 11.5 28T652-572q-12 12-28.5 12.5T595-571l-75-75v286q0 17-11.5 28.5T480-320q-17 0-28.5-11.5T440-360v-286Z" />
              </svg>
            </div>
          </div>
        )}

        {picture && (
          <div className="preview-img-wrapper" onClick={handleClick}>
            <img src={picture} />
          </div>
        )}
        <input
          type="file"
          ref={hiddenFileInput}
          style={{ display: "none" }}
          onChange={onSelect}
        />
        <div
          className={`save-btn ${picture ? "active" : ""}`}
          onClick={() => {
            if (picture) uploadImage();
          }}
        >
          Save
        </div>
      </div>
    );
  };

  const uploadBackground = () => {
    return (
      <div className="content background">
        <h3>Hello,</h3>
        <p>Please upload your photo</p>
        {picture == "" && (
          <div className="uploader-wrapper" onClick={handleClick}>
            <div className="add-img-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#ffff"
              >
                <path d="M427-427H233.78q-22.08 0-37.54-15.46-15.46-15.45-15.46-37.54t15.46-37.54Q211.7-533 233.78-533H427v-193.22q0-22.08 15.46-37.54 15.45-15.46 37.54-15.46t37.54 15.46Q533-748.3 533-726.22V-533h193.22q22.08 0 37.54 15.46 15.46 15.45 15.46 37.54t-15.46 37.54Q748.3-427 726.22-427H533v193.22q0 22.08-15.46 37.54-15.45 15.46-37.54 15.46t-37.54-15.46Q427-211.7 427-233.78V-427Z" />
              </svg>
            </div>
            <h3>Upload a Photo</h3>
            <p>Please select an image to generate your custom calendar skin</p>
          </div>
        )}

        {picture && (
          <div className="preview-img-wrapper" onClick={handleClick}>
            <img src={picture} />
          </div>
        )}
        <input
          type="file"
          ref={hiddenFileInput}
          style={{ display: "none" }}
          onChange={onSelect}
        />
        <div
          className={`save-btn ${picture ? "active" : ""}`}
          onClick={() => {
            if (picture) uploadImage();
          }}
        >
          Save
        </div>
      </div>
    );
  };

  const uploadImage = async () => {
    let url = `https://lg-calendar-tv-app-default-rtdb.firebaseio.com/tv/${familyKey}/members/${key}.json`;

    let request = new Request(url, {
      method: "PATCH",
      body: JSON.stringify({ profileimg: picture }),
    });

    if (mode == "background") {
      url = `https://lg-calendar-tv-app-default-rtdb.firebaseio.com/tv/${familyKey}.json`;
      request = new Request(url, {
        method: "PATCH",
        body: JSON.stringify({ backgroundimg: picture }),
      });
    }
    const response = await fetch(request);
    alert('Uploaded successfully!')
  };

  return (
    <div className="App">
      {mode == "profile" && uploadProfile()}
      {mode == "background" && uploadBackground()}
    </div>
  );
}

export default App;
